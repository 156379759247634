<template>
  <button type="button" class="btn btn-outline-secondary" v-on:click="$root.$emit('load-more', {})">
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'ButtonLoadMore',
  data: function () {
    return {
      //
    };
  },
  props: {
    //
  },
  methods: {
    //
  },
};
</script>
