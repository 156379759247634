<template>
  <form v-on:submit.prevent="submitForm()">
    <p>Для смены пароля введите старый пароль, а затем новый пароль 2 раза.</p>
    <input type="hidden" name="formid" v-model="form.formid" />
    <input type="hidden" name="_token" v-model="form._token" />
    <input type="hidden" name="fullname" v-model="form.fullname" />
    <input type="hidden" name="chpwd" v-model="form.chpwd" />

    <div class="my-1">
      <div class="input-group">
        <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-user" /></div>
        <input
          type="password"
          class="form-control"
          placeholder="старый пароль"
          name="old_password"
          v-model="form.old_password" />
      </div>
      <div data-error ref="error-old_password" class="alert alert-danger my-1 p-1"></div>
    </div>

    <p class="mt-3">Придумайте новый пароль:</p>

    <div class="my-1">
      <div class="input-group">
        <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-user" /></div>
        <input
          type="password"
          class="form-control"
          placeholder="новый пароль"
          name="password"
          v-model="form.password" />
      </div>
      <div data-error ref="error-password" class="alert alert-danger my-1 p-1"></div>
    </div>

    <div class="my-1">
      <div class="input-group">
        <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-user" /></div>
        <input
          type="password"
          class="form-control"
          placeholder="новый пароль ещё раз"
          name="password_confirmation"
          v-model="form.password_confirmation" />
      </div>
      <div data-error ref="error-password_confirmation" class="alert alert-danger my-1 p-1"></div>
    </div>

    <div data-error ref="error-common" class="alert alert-info mb-1 mt-3 p-1"></div>

    <div class="my-1 mt-2">
      <button type="submit" class="btn btn-primary">
        <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon> Сохранить
      </button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';
library.add(faUser, faKey);

export default {
  data: function () {
    return {
      form: {
        formid: 'profile-chpwd',
        _token: this.csrf,
        id: parseInt(this.id),
        fullname: this.fullname,
        chpwd: 1,
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      isLoading: false,
    };
  },
  props: {
    csrf: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    fullname: {
      type: String,
      default: '',
    },
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    submitForm() {
      this.isLoading = true;

      Object.keys(this.$refs).forEach((key) => {
        this.$refs[key].textContent = '';
      });

      axios
        .post(`/evocms-user/profile/${this.form.id}`, this.form)
        .then((res) => {
          if (res.data.status == 'ok') {
            this.$refs[`error-common`].textContent = 'Успешно сохранено!';

            this.form.old_password = '';
            this.form.password = '';
            this.form.password_confirmation = '';
          }
          if (res.data.status == 'error') {
            const errors = res.data.errors || {};
            const fieldErrors = errors.validation || {};
            const customErrors = errors.customErrors || {};
            const commonErrors = errors.common || [];

            Object.keys(fieldErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = fieldErrors[key].join(' ');
            });

            Object.keys(customErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = customErrors[key].join(' ');
            });

            this.$refs[`error-common`].textContent = commonErrors.join(' ');
          }
        })
        .catch((err) => {
          this.$refs[`error-common`].textContent = 'Ошибка на сервере, обновите страницу и попробуйте ещё раз.';
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
