<template>
  <!-- страница корзины -->
  <div class="mb-4">
    <div v-if="loading" aria-hidden="true" class="placeholder-glow row">
      <!-- placeholder -->
      <span class="placeholder col-10 ms-2 mb-2"></span>
      <span class="placeholder col-5 ms-2 mb-2"></span>
      <span class="placeholder col-3 ms-2 mb-2"></span>
      <a href="#" tabindex="-1" class="btn btn-primary ms-2 mb-2 disabled placeholder col-5" aria-hidden="true"></a>
    </div>
    <template v-else>
      <template v-if="amount == 0"
        >{{ str }}<br />
        <a v-if="page == 'order'" href="/cart" class="mt-3">Назад к корзине</a>
      </template>
      <template v-else
        >{{ str
        }}<template v-if="orderTotal"
          ><br />на сумму <nobr>{{ orderTotal }} ₽</nobr></template
        ><br />
        <a v-if="page == 'order'" href="/cart" class="mt-3">Назад к корзине</a>
        <a v-if="page == 'cart'" href="/order" class="btn btn-primary mt-3">Оформить заказ</a>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OrderTotal',
  props: {
    order: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
    orderTotal: {
      type: Number,
      default: 0,
      required: true,
    },
    page: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: function () {
    return {
      loading: true,
      //
      amount: 0,
      str: '',
    };
  },
  computed: {
    //
  },
  watch: {
    order: {
      deep: true,
      // eslint-disable-next-line
      handler(val, oldVal) {
        this.loading = true;

        this.amount = 0;
        if (Object.keys(val).length) {
          this.amount = Object.values(val).reduce((total, item) => parseInt(total) + parseInt(item['amount']), 0);
        }

        this.str = 'Товары не выбраны.';
        if (this.amount > 0) {
          this.str = this.plural(
            this.amount,
            `Выбран ${this.amount} товар`,
            `Выбраны ${this.amount} товара`,
            `Выбрано ${this.amount} товаров`
          );
        }

        this.loading = false;
      },
    },
  },
  created() {
    this.loading = true;
  },
  methods: {
    plural(n, form1, form2, form5) {
      n = Math.abs(n) % 100;
      const n1 = n % 10;
      if (n > 10 && n < 20) return form5;
      if (n1 > 1 && n1 < 5) return form2;
      if (n1 == 1) return form1;
      return form5;
    },
  },
};
</script>
