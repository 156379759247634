<template>
  <div class="my-1 cart_inner">
    <div v-if="loading" aria-hidden="true" class="placeholder-glow row pt-1">
      <!-- placeholder -->
      <span class="placeholder col-8 ms-2 mb-2"></span>
      <span class="placeholder col-5 ms-2 mb-2"></span>
    </div>
    <template v-else>
      <a :class="{ 'd-none': amount }" :href="link">Как сделать заказ?<br /></a>
      {{ str }}
      <template v-if="amount > 0"><br /><a href="/cart">Перейти к корзине</a></template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CartMini',
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
    link: {
      type: String,
      default: '#',
    },
  },
  data: function () {
    return {
      loading: true,
      //
      amount: 0,
      str: '',
    };
  },
  watch: {
    cart: {
      deep: true,
      // eslint-disable-next-line
      handler(val, oldVal) {
        this.loading = true;

        this.amount = 0;
        if (typeof val === 'object' && Object.keys(val).length) {
          this.amount = Object.values(val).reduce((total, item) => {
            return parseInt(total) + parseInt(item['amount']);
          }, 0);
        }

        this.str = 'В корзине ничего нет.';
        if (this.amount > 0) {
          this.str = this.plural(
            this.amount,
            `В корзине ${this.amount} товар.`,
            `В корзине ${this.amount} товара.`,
            `В корзине ${this.amount} товаров`
          );
        }

        this.loading = false;
      },
    },
  },
  methods: {
    plural(n, form1, form2, form5) {
      n = Math.abs(n) % 100;
      const n1 = n % 10;
      if (n > 10 && n < 20) return form5;
      if (n1 > 1 && n1 < 5) return form2;
      if (n1 == 1) return form1;
      return form5;
    },
  },
};
</script>
