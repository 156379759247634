<template>
  <div>
    <!-- для тех кто логинен - показать минимальную форму -->
    <p>
      После оформления заказа вы получите автоматический ответ cо списком заказанного и
      <span class="underline">номером заказа</span>.
    </p>
    <p>
      Наши менеджеры подтвердят наличие деталей и свяжутся с вами по электронной почте. Если вы хотите задать вопрос по
      заказу - звоните и будьте готовы назвать номер заказа.
    </p>

    <div class="alert alert-info" role="alert">
      При заказе будут использованы данные из вашего профиля. Если вы хотите поменять данные в профиле - перейдите в
      <a :href="url.profile" class="text-decoration-underline">Редактирование профиля</a>.
    </div>
    <div class="alert alert-success" role="alert">
      Перед оформлением заказа проверьте, пожалуйста, контактные данные, а затем нажмите "Отправить заказ". Если это не
      вы - нажмите <a :href="url.logout" class="text-decoration-underline">Выйти</a> и продолжите оформление заказа.
    </div>

    <form
      enctype="multipart/form-data"
      class="needs-validation"
      novalidate
      v-on:submit.prevent="$emit('make-order', form)">
      <div class="row my-1">
        <div class="col-12 col-sm-4 text-sm-end">Адрес эл. почты</div>
        <div class="col-12 col-sm-8">{{ values.email }}</div>
      </div>
      <div class="row my-1">
        <div class="col-12 col-sm-4 text-sm-end">Имя и фамилия</div>
        <div class="col-12 col-sm-8">{{ values.fullname }}</div>
      </div>
      <div class="row my-1">
        <div class="col-12 col-sm-4 text-sm-end">Телефон</div>
        <div class="col-12 col-sm-8">{{ values.phone }}</div>
      </div>
      <div class="row my-1">
        <div class="col-12 col-sm-4 text-sm-end">Доп. телефон</div>
        <div class="col-12 col-sm-8">{{ values.mobilephone }}</div>
      </div>

      <hr />

      <div class="row my-1">
        <label class="col-12 col-sm-4 col-form-label text-sm-end form-label">Доставка</label>
        <div class="col-12 col-sm-8">
          <div class="form-check">
            <input
              type="radio"
              name="deliverytype"
              class="form-check-input"
              id="withlogin-deliverytype1"
              value="1"
              v-model="form.deliverytype" />
            <label class="form-label form-check-label" for="withlogin-deliverytype1">Заберу сам</label>
          </div>
          <div class="form-check">
            <input
              type="radio"
              name="deliverytype"
              class="form-check-input"
              id="withlogin-deliverytype2"
              value="2"
              v-model="form.deliverytype" />
            <label class="form-label form-check-label" for="withlogin-deliverytype2"
              >Доставка по С.-Петербургу
              <small class="text-muted"><a href="/dostavka" target="_blank">стоимость и какие районы?</a></small></label
            >
          </div>
          <div class="form-check">
            <input
              type="radio"
              name="deliverytype"
              class="form-check-input"
              id="withlogin-deliverytype3"
              value="3"
              v-model="form.deliverytype" />
            <label class="form-label form-check-label" for="withlogin-deliverytype3"
              >Отправка ТК
              <small class="text-muted"><a href="/dostavka#tk" target="_blank">какие ТК используем?</a></small></label
            >
          </div>
        </div>
      </div>
      <div class="row my-1" v-show="form.deliverytype == 3">
        <label for="validation-tk" class="col-12 col-sm-4 col-form-label text-sm-end form-label"
          >Транспортная компания</label
        >
        <div class="col-12 col-sm-8">
          <select name="tk" class="form-control" id="validation-tk" v-model="form.tk">
            <option v-for="el in values.tklist" :key="el.id" :value="el.id">{{ el.name }} {{ el.comment }}</option>
          </select>
          <small class="text-muted"
            >Мы отправляем все заказы в жёсткой обрешетке, потому что это требование транспортных компаний к отправке
            автодеталей.</small
          >
        </div>
      </div>

      <div class="row my-1" v-show="form.deliverytype == 2 || form.deliverytype == 3">
        <div class="col-12 col-sm-4 text-sm-end">Адрес доставки</div>
        <div class="col-12 col-sm-8 position-relative">
          <VueSuggestions
            :model.sync="form.address"
            :coordinates.sync="form.coordinates"
            :placeholder="'Начните вводить'"
            class="form-control"
            :options="suggestionOptions">
          </VueSuggestions>
        </div>
      </div>

      <hr />

      <div class="row my-1">
        <label for="validationDefault05" class="col-12 col-sm-4 col-form-label text-sm-end form-label"
          >Комментарий к заказу</label
        >
        <div class="col-12 col-sm-8">
          <textarea
            name="comment"
            class="form-control"
            id="validationDefault05"
            placeholder=""
            v-model="form.comment"></textarea>
        </div>
      </div>

      <div class="row my-1">
        <div class="col-12 col-sm-8 offset-sm-4">
          <button class="btn btn-primary" type="submit">Отправить заказ</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import VueSuggestions from 'vue-suggestions';

export default {
  data: function () {
    return {
      form: {
        deliverytype: 1,
        tk: 'na',
        comment: '',
        address: '',
        coordinates: {
          latitude: '',
          longitude: '',
        },
      },
      // datata token
      token: process.env.VUE_APP_DADATA_API_KEY,
      // dadata
      suggestionOptions: {
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
        token: process.env.VUE_APP_DADATA_API_KEY,
        type: 'ADDRESS',
        scrollOnFocus: false,
        triggerSelectOnBlur: false,
        triggerSelectOnEnter: false,
        addon: 'none',
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
        //onSelect(suggestion) { },
      },
    };
  },
  props: {
    url: {
      type: Object,
      default() {
        return {};
      },
    },
    values: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    VueSuggestions,
  },
  methods: {
    //
  },
  mounted() {
    this.form.address = this.values.address;
  },
};
</script>
