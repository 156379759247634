<template>
  <button
    class="btn navbar-btn btn-link ms-3 px-1"
    data-bs-target="#modalNavigation"
    data-bs-toggle="modal"
    type="button">
    <font-awesome-icon icon="fa-solid fa-bars" style="font-size: 2rem" />
    <span class="sr-only">Открыть меню</span>
  </button>
</template>

<script>
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faBars } from '@fortawesome/free-solid-svg-icons';
library.add(faBars);

export default {
  data: function () {
    return {
      //
    };
  },
  props: {
    //
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>
