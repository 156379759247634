<template>
  <form id="tab1_form" novalidate="" class="mt-4" v-on:submit.prevent="submitForm()">
    <!-- <input type="hidden" name="dates_alt" v-model="form.dates_alt" /> -->
    <!-- <input type="hidden" name="page" v-model="form.page" /> -->

    <div class="row g-2 mt-2">
      <div class="col-7 col-md-4 col-lg-3">
        <div class="form-floating">
          <input
            type="text"
            name="orderid"
            v-model="form.orderid"
            placeholder="Номер заказа"
            class="form-control"
            id="floatingInputField1"
            aria-label="Поиск по номеру заказа" />
          <label for="floatingInputField1">Номер заказа</label>
        </div>
      </div>

      <div class="col-5 col-md-8 text-md-start col-lg-9 mt-md-3 mt-2 text-center">
        <button class="btn btn-lg btn-primary me-1" type="submit">
          <font-awesome-icon icon="fa-solid fa-search"></font-awesome-icon
          ><span class="d-none d-md-inline"> Показать</span>
        </button>
        <button class="btn btn-lg btn-outline-secondary me-1" type="reset" v-on:click="resetForm()">
          <font-awesome-icon icon="fa-solid fa-xmark"></font-awesome-icon
          ><span class="d-none d-md-inline"> Сбросить</span>
        </button>
      </div>
    </div>

    <!--
    <div class="row">
        <div class="col-1 col-md-2 col-lg-1 col-xl-2 text-end">
            <span class="d-md-none d-lg-inline-block d-xl-none"><font-awesome-icon icon="fa-regular fa-calendar"></font-awesome-icon></span>
            <span class="d-none d-md-inline-block d-lg-none d-xl-inline-block">Даты заказов</span>
        </div>

        <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-2">
            <div class="input-group mb-2">
                <input type="text" name="dates" v-model="form.dates" class="DatePicker form-control" data-range="true" data-multiple-dates-separator=" - ">
                <div class="input-group-text">
                    <a href="javascript:;" onclick="$('input[name=dates]').val(''); $('input[name=dates_alt]').val(''); return true;"><font-awesome-icon icon="fa-regular fa-calendar-xmark"></font-awesome-icon></a>
                </div>
            </div>
        </div>
    </div>
  --></form>
</template>

<script>
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
library.add(faSearch, faXmark);
//
import { faCalendar, faCalendarXmark } from '@fortawesome/free-regular-svg-icons';
library.add(faCalendar, faCalendarXmark);

export default {
  name: 'FormCooperation',
  components: {
    FontAwesomeIcon,
  },
  props: {
    receive: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      form: {
        dates: '',
        dates_alt: '',
        page: 1,
        orderid: '',
        link: '', // зачем*
      },
    };
  },
  methods: {
    resetForm: function () {
      this.form.dates = '';
      this.form.dates_alt = '';
      this.form.page = 1;

      // location.reload();
      window.location.href = window.location.pathname;
    },
    submitForm: function () {
      window.location.href = window.location.pathname + '?orderid=' + this.form.orderid;
    },
  },
  mounted() {
    this.form = JSON.parse(this.receive);
  },
};
</script>
