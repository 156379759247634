<template>
  <div class="row">
    <div v-if="isSuccess" class="col-12 col-sm-11 col-md-9 col-lg-7 alert alert-success" role="alert">
      Спасибо за ваш интерес к нашей компании! Мы скоро свяжемся с вами.
    </div>
    <div v-else class="col-12 col-sm-11 col-md-9 col-lg-7">
      <p>Заполните заявку о сотрудничестве и мы свяжемся с вами.</p>

      <form class="mb-5" v-on:submit.prevent="formSubmit()" method="post">
        <div class="row my-1">
          <label for="validation-company" class="col-12 col-sm-4 col-form-label text-sm-end form-label"
            >Название компании</label
          >
          <div class="col-12 col-sm-8">
            <input type="text" name="company" id="validation-company" class="form-control" v-model="form.company" />
            <div class="valid-feedback">Заполнено корректно.</div>
            <div class="invalid-feedback">Заполните ваше имя.</div>
          </div>
        </div>

        <div class="row my-1">
          <label for="validation-city" class="col-12 col-sm-4 col-form-label text-sm-end form-label"
            ><span class="text-danger">*</span> Город</label
          >
          <div class="col-12 col-sm-8">
            <input type="text" name="city" id="validation-city" class="form-control" v-model="form.city" />
            <div class="valid-feedback">Заполнено корректно.</div>
            <div class="invalid-feedback">Заполните город.</div>
          </div>
        </div>

        <div class="row my-1">
          <label for="validation-address" class="col-12 col-sm-4 col-form-label text-sm-end form-label">Адрес</label>
          <div class="col-12 col-sm-8">
            <input type="text" name="address" id="validation-address" class="form-control" v-model="form.address" />
            <div class="valid-feedback">Заполнено корректно.</div>
            <div class="invalid-feedback">Заполните адрес.</div>
          </div>
        </div>

        <div class="row my-1">
          <label for="validation-name" class="col-12 col-sm-4 col-form-label text-sm-end form-label"
            ><span class="text-danger">*</span> Контактное лицо</label
          >
          <div class="col-12 col-sm-8">
            <input type="text" name="name" id="validation-name" class="form-control" v-model="form.name" />
            <div class="valid-feedback">Заполнено корректно.</div>
            <div class="invalid-feedback">Заполните имя.</div>
          </div>
        </div>

        <div class="row my-1">
          <div class="col-12 col-sm-4 col-form-label text-sm-end form-label">Как с вами связаться:</div>
          <div class="col-12 col-sm-8">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="callme"
                id="callme_email"
                value="email"
                v-model="form.callme" />
              <label class="form-check-label" for="callme_email">Напишите мне письмо</label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="callme"
                id="callme_phone"
                value="phone"
                v-model="form.callme" />
              <label class="form-check-label" for="callme_phone">Позвоните мне</label>
            </div>
          </div>
        </div>

        <div class="row my-1" v-show="form.callme == 'email'">
          <label for="validation-email" class="col-12 col-sm-4 col-form-label text-sm-end form-label"
            ><span class="text-danger">*</span> Адрес эл. почты</label
          >
          <div class="col-12 col-sm-8">
            <input type="email" name="email" id="validation-email" class="form-control" v-model="form.email" />
            <div class="valid-feedback">Заполнено корректно.</div>
            <div class="invalid-feedback">Заполните адрес правильно.</div>
          </div>
        </div>

        <div class="row my-1" v-show="form.callme == 'phone'">
          <label for="validation-phone" class="col-12 col-sm-4 col-form-label text-sm-end form-label"
            ><span class="text-danger">*</span> Телефон для связи</label
          >
          <div class="col-12 col-sm-8">
            <masked-input
              v-model="form.phone"
              mask="\+\7 (111) 111-1111"
              type="tel"
              name="phone"
              id="validation-phone"
              class="form-control" />
          </div>
        </div>

        <div class="row my-1">
          <div class="col-12">
            <textarea
              class="form-control"
              id="message"
              placeholder="Комментарии или вопросы"
              name="message"
              rows="5"
              v-model="form.message"></textarea>
          </div>
        </div>

        <div class="row my-1">
          <div class="col-12 col-sm-8 offset-sm-4">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="checkAgree" v-model="form.agree" />
              <label class="form-check-label" for="checkAgree"
                >Согласие на <a href="/privacy">обработку персональных данных</a></label
              >
            </div>
          </div>
        </div>

        <div v-if="errors.length" class="row my-1 alert alert-danger" role="alert">
          <b>Пожалуйста, исправьте указанные ошибки:</b>
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>

        <div class="my-1 text-end">
          <button type="submit" class="btn btn-primary">
            <font-awesome-icon icon="fas fa-check"></font-awesome-icon> Отправить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

import MaskedInput from 'vue-masked-input';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faCheck } from '@fortawesome/free-solid-svg-icons';
library.add(faCheck);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

export default {
  name: 'FormCooperation',
  components: {
    MaskedInput,
  },
  data: function () {
    return {
      isSuccess: false,
      errors: [],
      //
      form: {
        company: '',
        city: '',
        address: '',
        name: '',
        email: '',
        phone: '',
        message: '',
        agree: true,
        //
        callme: 'email',
      },
      // что в отправку
      formData: null,
    };
  },
  methods: {
    formSubmit: function () {
      this.errors = [];

      //if (!this.form.company) {
      //this.errors.push('Требуется указать название компании.');
      //}

      if (this.form.city.length == 0) {
        this.errors.push('Требуется указать город.');
      }

      //if (!this.form.address) {
      //this.errors.push('Требуется указать адрес.');
      //}

      if (this.form.name.length == 0) {
        this.errors.push('Требуется указать имя.');
      }

      switch (this.form.callme) {
        case 'email':
          if (this.form.email.length == 0) {
            this.errors.push('Требуется указать адрес эл. почты.');
          } else if (this.validEmail(this.form.email) == false) {
            this.errors.push('Требуется указать адрес эл. почты правильно.');
          }
          break;
        case 'phone':
          if (this.form.phone.length == 0) {
            this.errors.push('Требуется указать телефон.');
          }
          break;
        default:
          this.errors.push('Не указано как нужно связаться.');
      }

      //if (!this.form.message) {
      //this.errors.push('Впишите вопрос или комментарий.');
      //}

      if (this.form.agree === false) {
        this.errors.push('Подтвердите согласие на использование персональных данных.');
      }

      if (this.errors.length == 0) {
        // нет ошибок
        this.formSend();
      }
    },
    formSend: function () {
      // что в отправку
      this.formData = new FormData();
      //
      Object.keys(this.form).forEach((key) => {
        this.formData.append(key, this.form[key]);
      });

      axios
        .post(`${this.$store.getters.urlformappl}`, this.formData)
        .then((res) => {
          if (res.data.status) {
            this.$bs5utils.Snack.show('info', 'Ваше сообщение отправлено.', this.$store.getters.delay.success);
            this.resetForm();
            this.isSuccess = true;

            // основной сайт
            if (res.data.status && window.location.hostname == 'www.smtauto.ru') {
              // eslint-disable-next-line
              // !!! error! check!
              //ym(18965677, 'reachGoal', 'cooperation_request');
            }

            return true;
          }

          for (const key in res.data.errors) {
            res.data.errors[key].forEach((element) => {
              this.errors.push(element);
            });
          }

          this.$bs5utils.Snack.show(
            'warning',
            'Не все требуемые поля формы заполнены, проверьте ещё раз.',
            this.$store.getters.delay.warning
          );
        })
        .catch((err) => {
          this.$bs5utils.Snack.show(
            'danger',
            'Не удалось отправить данные формы, обновите страницу и попробуйте ещё раз.',
            this.$store.getters.delay.error
          );

          console.error(err);
        });
    },
    resetForm: function () {
      this.errors = [];
      //
      Object.keys(this.form).forEach((key) => {
        this.form[key] = '';
      });
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>
