<template>
  <transition name="btn-scroll">
    <div v-show="isVisible" class="scroll-to-top">
      <a href="#page_top" v-on:click.prevent="scrollToTop"><i></i></a>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ScrollToTop',
  data: function () {
    return {
      isVisible: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
  mounted() {
    // скролл окна
    window.addEventListener(
      'scroll',
      () => {
        if (window.scrollY > window.innerHeight / 2) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      },
      { passive: true }
    );
  },
};
</script>

<style scoped>
/* стрелка скролл вверх */
.scroll-to-top {
  position: fixed;
  right: 0.5rem;
  bottom: 1.5rem;
  display: inline-block;
  border-radius: 0;
  background-color: var(--scroll-btn-bg);

  z-index: 100;
  box-shadow: 1px 1px 5px #666;
}
@media (max-width: 480px) {
  .scroll-to-top {
    margin-bottom: 2rem;
  }
}
.scroll-to-top i {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1;
}
.scroll-to-top i:before {
  content: '△';
}
.scroll-to-top a {
  padding: 0.5rem 0.9rem;
  display: inline-block;
  color: #ff8600;
  transition: color 0.25s, background-color 0.25s;
}
@media (hover: hover) and (pointer: fine) {
  .scroll-to-top a:hover {
    color: #eee;
    background-color: #ff8600;
  }
}

.btn-scroll-enter-active,
.btn-scroll-leave-active {
  transition: opacity 0.5s;
}
.btn-scroll-leave-to,
.btn-scroll-enter {
  opacity: 0;
}
</style>
