import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        params: {
            url: {
                cart: '/backend/cart',
                order: '/backend/order',
                productsmore: '/backend/products/more',
                profile: '/backend/profile',
                search: '/form/search',
                formappl: '/form/appl',
            },
            page: {
                search: '/search',
            }
        },
        delay: { info: 7500, success: 10000, warning: 12000, error: 15000 },
        loading: false,
    },
    getters: {
        // все урлы
        urls: state => {
            return state.params.url;
        },
        // по одному
        urlcart: state => {
            return state.params.url.cart;
        },
        urlorder: state => {
            return state.params.url.order;
        },
        urlproductsmore: state => {
            return state.params.url.productsmore;
        },
        urlprofile: state => {
            return state.params.url.profile;
        },
        urlsearch: state => {
            return state.params.url.search;
        },
        urlformappl: state => {
            return state.params.url.formappl;
        },
        // все страницы
        pages: state => {
            return state.params.page;
        },
        // по одному
        pagesearch: state => {
            return state.params.page.search;
        },

        delay: state => {
            return state.delay;
        },
        loading: state => {
            return state.loading
        },
    },
    mutations: {
        switchLoading: (state, payload) => {
            state.loading = payload
        },
    },
    actions: {
        startLoading: (context) => {
            context.commit('switchLoading', true);
        },
        stopLoading: (context) => {
            context.commit('switchLoading', false);
        },
    },
});
