<template>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4">
      <form v-on:submit.prevent="submitForm()">
        <input type="hidden" name="formid" v-model="form.formid" />
        <input type="hidden" name="_token" v-model="form._token" />

        <div class="my-1">
          <div class="input-group">
            <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-at" /></div>
            <input
              type="text"
              class="form-control"
              placeholder="эл. почта"
              name="email"
              value="[+email.value+]"
              v-model="form.email" />
          </div>
          <div data-error ref="error-email" class="alert alert-danger my-1 p-1"></div>
        </div>

        <div data-error ref="error-common" class="alert alert-info mb-1 mt-3 p-1"></div>

        <div class="my-1">
          <button type="submit" class="btn btn-primary">
            <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon> Далее
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faCheck, faAt } from '@fortawesome/free-solid-svg-icons';
library.add(faCheck, faAt);

export default {
  data: function () {
    return {
      form: {
        formid: 'profile-password-remind',
        _token: this.csrf,
        email: '',
      },
      isLoading: false,
    };
  },
  props: {
    csrf: {
      type: String,
      default: '',
    },
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    submitForm() {
      this.isLoading = true;

      Object.keys(this.$refs).forEach((key) => {
        this.$refs[key].textContent = '';
      });

      axios
        .post(`/evocms-user/reset-password`, this.form)
        .then((res) => {
          if (res.data.status == 'ok') {
            this.$refs[`error-common`].textContent =
              'На указанную почту отправлено письмо с инструкцией по восстановлению пароля.';

            this.form.email = '';
          }
          if (res.data.status == 'error') {
            const errors = res.data.errors || {};
            const fieldErrors = errors.validation || {};
            const customErrors = errors.customErrors || {};
            const commonErrors = errors.common || [];

            Object.keys(fieldErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = fieldErrors[key].join(' ');
            });

            Object.keys(customErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = customErrors[key].join(' ');
            });

            this.$refs[`error-common`].textContent = commonErrors.join(' ');
          }
        })
        .catch((err) => {
          this.$refs[`error-common`].textContent = 'Ошибка на сервере, обновите страницу и попробуйте ещё раз.';
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
