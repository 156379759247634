<template>
  <form v-on:submit.prevent="submitForm()">
    <p>На этой странице вы можете исправить данные вашего профиля.</p>
    <input type="hidden" name="formid" v-model="form.formid" />
    <input type="hidden" name="_token" v-model="form._token" />

    <div class="my-1">
      <div class="input-group">
        <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-user" /></div>
        <input type="text" class="form-control" placeholder="имя, фамилия" name="fullname" v-model="form.fullname" />
      </div>
      <div data-error ref="error-fullname" class="alert alert-danger my-1 p-1"></div>
    </div>

    <div class="my-1">
      <div class="input-group">
        <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-phone" /></div>
        <input type="text" class="form-control" placeholder="основной телефон" name="phone" v-model="form.phone" />
      </div>
      <div data-error ref="error-phone" class="alert alert-danger my-1 p-1"></div>
    </div>

    <div class="my-1">
      <div class="input-group">
        <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-phone" /></div>
        <input
          type="text"
          class="form-control"
          placeholder="основной телефон"
          name="mobilephone"
          v-model="form.mobilephone" />
      </div>
      <div data-error ref="error-mobilephone" class="alert alert-danger my-1 p-1"></div>
    </div>

    <p class="mt-3">Укажите адрес доставки, чтобы сохранить его в профиле.</p>

    <div class="my-1">
      <div class="input-group">
        <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-map-marker-alt" /></div>
        <VueSuggestions
          :model.sync="form.client_address"
          :coordinates.sync="form.coordinates"
          :placeholder="'Начните вводить'"
          class="form-control"
          :options="suggestionOptions">
        </VueSuggestions>
        <!-- <input
          type="text"
          class="form-control"
          placeholder="адрес доставки"
          name="client_address"
          v-model="form.client_address" /> -->
      </div>
      <div data-error ref="error-client_address" class="alert alert-danger my-1 p-1"></div>
    </div>

    <div data-error ref="error-common" class="alert alert-info mb-1 mt-3 p-1"></div>

    <div class="my-1 mt-2">
      <button type="submit" class="btn btn-primary" :disabled="isLoading">
        <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon> Сохранить
      </button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

import VueSuggestions from 'vue-suggestions';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faUser, faPhone, faCity, faMapMarkerAlt, faCheck, faKey } from '@fortawesome/free-solid-svg-icons';
library.add(faUser, faPhone, faCity, faMapMarkerAlt, faCheck, faKey);

export default {
  data: function () {
    return {
      form: {
        formid: 'profile-edit',
        _token: this.csrf,
        id: parseInt(this.id),
        fullname: this.fullname,
        phone: this.phone,
        mobilephone: this.mobilephone,
        client_address: this.client_address,
        coordinates: '',
      },
      isLoading: false,
      // datata token
      token: process.env.VUE_APP_DADATA_API_KEY,
      // dadata
      suggestionOptions: {
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
        token: process.env.VUE_APP_DADATA_API_KEY,
        type: 'ADDRESS',
        scrollOnFocus: false,
        triggerSelectOnBlur: false,
        triggerSelectOnEnter: false,
        addon: 'none',
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
        //onSelect(suggestion) { /* */ },
      },
    };
  },
  props: {
    csrf: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    fullname: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    mobilephone: {
      type: String,
      default: '',
    },
    client_address: {
      type: String,
      default: '',
    },
  },
  components: {
    VueSuggestions,
    FontAwesomeIcon,
  },
  methods: {
    submitForm() {
      this.isLoading = true;

      Object.keys(this.$refs).forEach((key) => {
        this.$refs[key].textContent = '';
      });

      axios
        .post(`/evocms-user/profile/${this.form.id}`, this.form)
        .then((res) => {
          if (res.data.status == 'ok') {
            this.$refs[`error-common`].textContent = 'Успешно сохранено!';
          }
          if (res.data.status == 'error') {
            const errors = res.data.errors || {};
            const fieldErrors = errors.validation || {};
            const customErrors = errors.customErrors || {};
            const commonErrors = errors.common || [];

            Object.keys(fieldErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = fieldErrors[key].join(' ');
            });

            Object.keys(customErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = customErrors[key].join(' ');
            });

            this.$refs[`error-common`].textContent = commonErrors.join(' ');
          }
        })
        .catch((err) => {
          this.$refs[`error-common`].textContent = 'Ошибка на сервере, обновите страницу и попробуйте ещё раз.';
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
