<template>
  <form method="get" class="search_by_code my-2" v-on:submit.prevent="searchForm($event)">
    <div class="input-group input-group-sm">
      <span class="input-group-text">Поиск</span>
      <input
        v-model.trim="search"
        class="form-control mr-1 mr-sm-2"
        type="search"
        placeholder="oem номер детали или наш артикул"
        aria-label="Поиск" />
      <button class="btn btn-sm btn-outline-success my-0" type="submit">
        <font-awesome-icon icon="fas fa-search" /> Найти
      </button>
    </div>
  </form>
</template>

<script>
import Vue from 'vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faSearch } from '@fortawesome/free-solid-svg-icons';
library.add(faSearch);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

export default {
  name: 'CatalogSearchForm',
  props: {
    pageSearch: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      search: '',
    };
  },
  watch: {
    search() {
      localStorage.setItem('search', this.search);
    },
    // eslint-disable-next-line
    pageSearch(val, oldval) {
      if (val == true) {
        // восстановить значение в поиск
        let s = null;
        s = localStorage.getItem('search');
        if (s !== null) {
          this.search = s;
          // искать
          this.$root.$emit('do-search', {});
        }
      }
    },
  },
  methods: {
    searchForm() {
      if (this.pageSearch == true) {
        // на странице поиска
        this.$root.$emit('do-search', {});
      } else {
        // на другой странице
        window.location.href = this.$store.getters.pagesearch;
      }
    },
  },
};
</script>
