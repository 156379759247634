<template>
  <div class="cart_nano">
    <template v-if="amount > 0"
      ><a :href="link">{{ str }}</a></template
    >
  </div>
</template>

<script>
export default {
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
    link: {
      type: String,
      default: '#',
      required: true,
    },
  },
  data: function () {
    return {
      amount: 0,
      str: '',
    };
  },
  watch: {
    cart: {
      deep: true,
      // eslint-disable-next-line
      handler(val, oldVal) {
        this.amount = 0;
        if (typeof val === 'object' && Object.keys(val).length) {
          this.amount = Object.values(val).reduce((total, item) => {
            return parseInt(total) + parseInt(item['amount']);
          }, 0);
        }

        this.str = '';
        if (this.amount > 0) {
          this.str = this.amount;
        }
      },
    },
  },
  methods: {
    //
  },
};
</script>
