// require('bootstrap/dist/css/bootstrap.min.css')
import 'bootstrap/scss/bootstrap.scss'

require('@/assets/common.scss')
require('@/assets/styles.css')
require('@/assets/colors.css')
require('@/assets/header.scss')
require('@/assets/menutop.scss')
require('@/assets/menuleft.scss')
require('@/assets/blockleft.css')
require('@/assets/footer.scss')
require('@/assets/forms.css')
require('@/assets/sotr.css')
require('@/assets/partslist.css')
require('@/assets/orderblock.css')
require('@/assets/elements.scss')

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// или
//window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

// eslint-disable-next-line
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
// можно сделать global
// window.Fancybox = Fancybox;

import Vue from 'vue'
import store from './store'

Vue.config.productionTip = false

import axios from 'axios';
axios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        store.dispatch('startLoading');
        return config;
    },
    (error) => {
        // Do something with request error
        store.dispatch('startLoading');
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        // Do something with response data
        store.dispatch('stopLoading');
        return response;
    },
    (error) => {
        // Do something with response error
        store.dispatch('stopLoading');
        return Promise.reject(error);
    }
);

//import simpleStorage from 'simplestorage.js';

import Bs5Utils from 'bs5-utils';
const bs5utils = new Bs5Utils();
Vue.prototype.$bs5utils = bs5utils;

import CatalogSearchForm from './components/catalog-search-form.vue';
import CatalogSearchResult from './components/catalog-search-result.vue';
import CatalogLoadMore from './components/catalog-load-more.vue';

import CartNano from './components/cart-nano.vue';
import CartMini from './components/cart-mini.vue';
import CartList from './components/cart-list.vue';
import OrderTotal from './components/order-total.vue';
import OrderList from './components/order-list.vue';
import OrderForms from './components/order-forms.vue';

import ProfileEdit from './components/profile-edit.vue';
import ProfilePassword from './components/profile-password.vue';
// old import ProfileRemind from './components/profile-remind.vue';
import ProfilePasswordRemind from './components/profile-password-remind.vue';
import ProfilePasswordCreate from './components/profile-password-create.vue';

import ButtonBurgermenu from './components/button-burgermenu.vue';
import ButtonAddToCart from './components/button-addtocart.vue';
import ScrollToTop from './components/scroll-to-top.vue';
import SiteAlert from './components/site-alert.vue';
import HeaderTopmenu from './components/header-topmenu.vue';
import HeaderUser from './components/header-user.vue';

import FormCooperation from './components/form-cooperation.vue';
import FormProfileorders from './components/form-profileorders.vue';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

new Vue({
    name: 'SmartAutoApp',
    store,
    components: {
        CartNano,
        CartMini,
        CartList,
        OrderTotal,
        OrderList,
        OrderForms,
        //
        CatalogSearchForm,
        CatalogSearchResult,
        CatalogLoadMore,
        //
        ProfileEdit,
        ProfilePassword,
        // old ProfileRemind,
        ProfilePasswordRemind,
        ProfilePasswordCreate,
        //
        ButtonBurgermenu,
        ButtonAddToCart,
        ScrollToTop,
        SiteAlert,
        HeaderTopmenu,
        HeaderUser,
        //
        FormCooperation,
        FormProfileorders,
        //
        VueSlickCarousel,
    },
    data: {
        cart: {},
        order: {},
        deleted: {},
        synced: 0,
        //
        orderTotal: 0,
        // поиск
        search: '',
        pageSearch: false,
    },
    methods: {
        //загрузка из local storage
        loadData: function () {
            let s = null
            let v = null

            // корзина
            s = localStorage.getItem('cart')
            if (s !== null) {
                v = JSON.parse(s)
                if (Array.isArray(v)) {
                    this.cart = {}
                    this.saveCart()
                } else {
                    this.cart = v
                }
            } else {
                // чтобы было обновление свойства
                this.cart = {}
            }

            // заказ
            s = localStorage.getItem('order')
            if (s !== null) {
                v = JSON.parse(s)
                if (Array.isArray(v)) {
                    this.order = {}
                    this.saveOrder()
                } else {
                    this.order = v
                }
            } else {
                // чтобы было обновление свойства
                this.order = {}
            }

            // удалённые
            s = localStorage.getItem('deleted')
            if (s !== null) {
                v = JSON.parse(s)
                if (Array.isArray(v)) {
                    this.deleted = {}
                    this.saveDeleted()
                } else {
                    this.deleted = v
                }
            } else {
                // чтобы было обновление свойства
                this.deleted = {}
            }

            this.cartSync()
        },
        // сохранения
        saveCart: function () {
            return new Promise((resolve, reject) => {
                try {
                    localStorage.setItem('cart', JSON.stringify(this.cart))
                    resolve('Корзина сохранена в localStorage')
                } catch (err) {
                    reject(new Error('Не удалось сохранить корзину в localStorage, ' + err))
                }
                return
            })
        },
        saveOrder: function () {
            return new Promise((resolve, reject) => {
                try {
                    localStorage.setItem('order', JSON.stringify(this.order))

                    this.getOrderTotal()

                    resolve('Список заказа сохранён в localStorage')
                } catch (err) {
                    reject(new Error('Не удалось сохранить список заказа в localStorage'))
                }
                return
            })
        },
        saveDeleted: function () {
            return new Promise((resolve, reject) => {
                try {
                    localStorage.setItem('deleted', JSON.stringify(this.deleted))
                    resolve('Список удалённого сохранён в localStorage')
                } catch (err) {
                    reject(new Error('Не удалось сохранить список удалённого в localStorage'))
                }
                return
            })
        },
        // изменения
        addToCart: function (ev) {
            return new Promise((resolve, reject) => {
                if (ev.contentid == 0) {
                    this.$bs5utils.Snack.show('danger', 'Не удалось добавить в корзину, у товара не указан ID', this.$store.getters.delay.error)
                    reject(new Error('У товара не указан ID, не получается добавить в корзину'))
                    return
                }

                ev.extra.amount = 1
                ev.extra.added = Math.floor(Date.now() / 1000)

                if (typeof this.cart == undefined) {
                    this.cart = {}
                }

                let s = null

                // освежить корзину
                s = localStorage.getItem('cart')
                if (s !== null) {
                    this.cart = JSON.parse(s)
                }

                if (Object.prototype.hasOwnProperty.call(this.cart, ev.contentid)) {
                    this.cart[ev.contentid].amount += ev.extra.amount
                    this.cart[ev.contentid].added = ev.extra.added
                } else {
                    Vue.set(this.cart, ev.contentid, ev.extra)
                }

                this.saveCart().then(
                    () => {
                        this.cartSync()

                        // и добавить в заказ
                        if (typeof this.order == undefined) {
                            this.order = {}
                        }

                        let s = null

                        // освежить заказ
                        s = localStorage.getItem('order')
                        if (s !== null) {
                            this.order = JSON.parse(s)
                        }

                        if (Object.prototype.hasOwnProperty.call(this.order, ev.contentid)) {
                            this.order[ev.contentid] = ev.extra.amount
                        } else {
                            Vue.set(this.order, ev.contentid, ev.extra)
                        }

                        this.saveOrder()

                        this.$bs5utils.Snack.show('success', 'Добавлено в корзину', this.$store.getters.delay.success)
                        resolve('Добавлено в корзину')
                    },
                    err => {
                        this.$bs5utils.Snack.show('error', err, this.$store.getters.delay.error)
                        reject(new Error(err))
                    }
                )
            })
        },
        removeFromCart: function (id) {
            return new Promise((resolve, reject) => {
                if (typeof this.cart == undefined) {
                    this.cart = {}
                }

                if (typeof this.deleted == undefined) {
                    this.deleted = {}
                }

                let s = null

                // освежить корзину
                s = localStorage.getItem('cart')
                if (s !== null) {
                    this.cart = JSON.parse(s)
                }

                // освежить deleted
                s = localStorage.getItem('deleted')
                if (s !== null) {
                    this.deleted = JSON.parse(s)
                }

                if (Object.prototype.hasOwnProperty.call(this.cart, id) == false) {
                    // нет такого id
                    this.$bs5utils.Snack.show('danger', 'Нельзя удалить из корзины, нет товара с таким ID', this.$store.getters.delay.error)
                    reject(new Error('Нет такого ID товара'))
                    return
                }

                let extra = this.cart[id]
                extra.deleted = Math.round(new Date().getTime() / 1000)

                // сохраняем в deleted
                Vue.set(this.deleted, id, extra)

                // записать deleted
                this.saveDeleted().then(
                    () => {
                        Vue.delete(this.cart, id)

                        this.saveCart().then(
                            () => {
                                // удаление с сервера
                                axios.get(`${this.$store.getters.urlcart}/remove/${id}`)
                                    .then(res => {
                                        if (res.data.status) {
                                            // удалось

                                            let s = null

                                            // освежить deleted
                                            s = localStorage.getItem('deleted')
                                            if (s !== null) {
                                                this.deleted = JSON.parse(s)
                                            }

                                            // другое не нужно, так как удаление из корзины только на странице корзины
                                            Vue.delete(this.deleted, id)

                                            this.saveDeleted()
                                        }

                                        this.$root.$emit('reload-cart-list', {})
                                        resolve('Удалено из корзины.')
                                    })
                                    .catch(err => {
                                        console.error(err)
                                        this.$bs5utils.Snack.show('danger', err, this.$store.getters.delay.error)
                                        reject(new Error(err))
                                    })
                                    .finally(
                                        () => {
                                            if (typeof this.order == undefined) {
                                                this.order = {}
                                            }

                                            let s = null

                                            // освежить заказ
                                            s = localStorage.getItem('order')
                                            if (s !== null) {
                                                this.order = JSON.parse(s)
                                            }

                                            // из заказа
                                            if (Object.prototype.hasOwnProperty.call(this.order, id)) {
                                                Vue.delete(this.order, id)

                                                this.saveOrder()
                                            }
                                        }
                                    )
                            }
                        )
                    }
                )
            })
        },
        removeItemsFromCart: function (ids) {
            return new Promise((resolve) => {
                if (typeof this.cart == undefined) {
                    this.cart = {}
                }

                if (typeof this.deleted == undefined) {
                    this.deleted = {}
                }

                if (typeof this.order == undefined) {
                    this.order = {}
                }

                let s = null

                // освежить корзину
                s = localStorage.getItem('cart')
                if (s !== null) {
                    this.cart = JSON.parse(s)
                }

                // освежить deleted
                s = localStorage.getItem('deleted')
                if (s !== null) {
                    this.deleted = JSON.parse(s)
                }

                // освежить заказ
                s = localStorage.getItem('order')
                if (s !== null) {
                    this.order = JSON.parse(s)
                }

                ids.forEach(id => {
                    if (Object.prototype.hasOwnProperty.call(this.cart, id)) {
                        let extra = this.cart[id]
                        extra.deleted = Math.round(new Date().getTime() / 1000)

                        // сохраняем в deleted
                        Vue.set(this.deleted, id, extra)
                    }
                })

                // записать deleted
                this.saveDeleted().then(
                    () => {
                        ids.forEach(id => {
                            // из корзины
                            Vue.delete(this.cart, id)
                            // из заказа
                            Vue.delete(this.order, id)
                        })

                        this.saveCart().then(
                            () => {
                                this.saveOrder().then(
                                    () => {
                                        this.cartSync()
                                    }
                                )
                            }
                        )
                    }
                )
                resolve('Товар удалён из корзины.')
            })
        },
        cartIncreaseAmount: function (ev) {
            return new Promise((resolve, reject) => {
                let s = null

                // освежить корзину
                s = localStorage.getItem('cart')
                if (s !== null) {
                    this.cart = JSON.parse(s)
                }

                if (Object.prototype.hasOwnProperty.call(this.cart, ev.id) == false) {
                    this.$bs5utils.Snack.show('warning', 'Не удалось поменять количество, нет такого товара в корзине', this.$store.getters.delay.warning)
                    reject(new Error('Нет такого товара в корзине'))
                    return
                }

                this.cart[ev.id].amount++
                this.cart[ev.id].changing = true

                this.saveCart().then(
                    () => {
                        if (typeof this.order == undefined) {
                            this.order = {}
                        }

                        let s = null

                        // освежить заказ
                        s = localStorage.getItem('order')
                        if (s !== null) {
                            this.order = JSON.parse(s)
                        }

                        if (Object.prototype.hasOwnProperty.call(this.order, ev.id)) {
                            this.order[ev.id].amount = this.cart[ev.id].amount
                            this.saveOrder()
                        }

                        this.cartSync()
                        this.$bs5utils.Snack.show('success', 'Количество увеличено на 1шт', this.$store.getters.delay.success)
                        this.cart[ev.id].changing = false
                        resolve('Количество увеличено на 1шт')
                    }
                ).catch(
                    err => {
                        this.cart[ev.id].changing = false
                        reject(new Error(err))
                    }
                )
                return
            })
        },
        cartReduceAmount: function (ev) {
            return new Promise((resolve, reject) => {
                let s = null

                // освежить корзину
                s = localStorage.getItem('cart')
                if (s !== null) {
                    this.cart = JSON.parse(s)
                }

                if (Object.prototype.hasOwnProperty.call(this.cart, ev.id) == false) {
                    this.$bs5utils.Snack.show('warning', 'Не удалось поменять количество, нет такого товара в корзине', this.$store.getters.delay.warning)
                    reject(new Error('Нет такого товара в корзине'))
                    return
                }

                if (this.cart[ev.id].amount > 1) {
                    // много
                    this.cart[ev.id].amount--
                    this.cart[ev.id].changing = true

                    this.saveCart().then(
                        () => {
                            if (typeof this.order == undefined) {
                                this.order = {}
                            }

                            let s = null

                            // освежить заказ
                            s = localStorage.getItem('order')
                            if (s !== null) {
                                this.order = JSON.parse(s)
                            }

                            if (Object.prototype.hasOwnProperty.call(this.order, ev.id)) {
                                this.order[ev.id].amount = this.cart[ev.id].amount
                                this.saveOrder()
                            }

                            this.cartSync()

                            this.$bs5utils.Snack.show('success', 'Количество уменьшено на 1шт', this.$store.getters.delay.success)

                            this.cart[ev.id].changing = false
                            resolve('Количество уменьшено на 1шт')
                        }
                    ).catch(
                        err => {
                            this.$bs5utils.Snack.show('warning', err, this.$store.getters.delay.warning)

                            this.cart[ev.id].changing = false
                            reject(new Error(err))
                        }
                    )
                } else {
                    // последний
                    this.removeFromCart(ev.id).then(
                        res => {
                            this.$bs5utils.Snack.show('success', res, this.$store.getters.delay.success)
                            resolve(res)
                        },
                        err => {
                            this.$bs5utils.Snack.show('warning', err, this.$store.getters.delay.warning)
                            reject(err)
                        }
                    )
                }
                return
            })
        },
        cartChangeAmount: function (ev) {
            return new Promise((resolve, reject) => {
                let s = null

                // освежить корзину
                s = localStorage.getItem('cart')
                if (s !== null) {
                    this.cart = JSON.parse(s)
                }

                if (Object.prototype.hasOwnProperty.call(this.cart, ev.id) == false) {
                    this.$bs5utils.Snack.show('warning', 'Не удалось поменять количество, нет такого товара в корзине', this.$store.getters.delay.warning)
                    reject(new Error('Нет такого товара в корзине'))
                    return
                }

                this.cart[ev.id].amount = ev.value
                this.cart[ev.id].changing = true

                this.saveCart().then(
                    () => {
                        if (typeof this.order == undefined) {
                            this.order = {}
                        }

                        let s = null

                        // освежить заказ
                        s = localStorage.getItem('order')
                        if (s !== null) {
                            this.order = JSON.parse(s)
                        }

                        if (Object.prototype.hasOwnProperty.call(this.order, ev.id)) {
                            this.order[ev.id].amount = this.cart[ev.id].amount
                            this.saveOrder()
                        }

                        this.cartSync()
                        this.$bs5utils.Snack.show('success', 'Количество изменено', this.$store.getters.delay.success)
                        this.cart[ev.id].changing = false
                        resolve('Количество изменено')
                    }
                ).catch(
                    err => {
                        this.cart[ev.id].changing = false
                        reject(new Error(err))
                    }
                )
                return
            })
        },
        cartSync: function () {
            // 1. загрузка корзины на сервер при загрузке страницы
            // 2. загрузка корзины с сервера при загрузке страницы
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.$store.getters.urlcart}/sync`, {
                        cart: this.cart,
                        deleted: this.deleted,
                        synced: this.synced,
                    })
                    .then(res => {
                        if (res.data.status == false) {
                            reject(new Error('Ошибка синхронизации корзины, ошибка на сервере.'))
                            return
                        }

                        // синхронизация не удалась, но корзина проверена
                        if (res.data.synced == -1) {
                            if (res.data.cart) {
                                this.cart = Object.assign({}, res.data.cart)

                                this.saveCart().then(
                                    () => {
                                        let s = null

                                        // освежить заказ
                                        s = localStorage.getItem('order')
                                        if (s !== null) {
                                            this.order = JSON.parse(s)
                                        }

                                        // проверка заказа
                                        for (let key in this.order) {
                                            // есть в заказе, нет в корзине
                                            if (Object.prototype.hasOwnProperty.call(this.cart, key) == false) {
                                                Vue.delete(this.order, key)
                                            }
                                            // неправильное количество
                                            if (Object.prototype.hasOwnProperty.call(this.order, key) != false && this.cart[key].amount < this.order[key].amount) {
                                                this.order[key].amount = this.cart[key].amount
                                            }
                                        }

                                        this.saveOrder()
                                    }
                                ).finally(() => {
                                    this.$root.$emit('reload-cart-list', {})
                                })
                            } else {
                                this.$root.$emit('reload-cart-list', {})
                            }

                            resolve('Для синхронизации корзины нужен логин.')
                            return
                        }

                        // синхронизация не удалась
                        if (res.data.synced == 0) {
                            reject(new Error('Ошибка синхронизации корзины, что-то пошло не так.'))
                            return
                        }

                        // синхронизация удалась
                        if (res.data.synced != 0) {
                            this.cart = Object.assign({}, res.data.cart)
                            this.synced = res.data.synced

                            this.saveCart().then(
                                () => {
                                    let s = null

                                    // освежить deleted
                                    s = localStorage.getItem('deleted')
                                    if (s !== null) {
                                        this.deleted = JSON.parse(s)
                                    }

                                    if (res.data.deleted && Object.keys(res.data.deleted).length) {
                                        for (let key in res.data.deleted) {
                                            if (Object.prototype.hasOwnProperty.call(this.deleted, key)) {
                                                Vue.delete(this.deleted, key)
                                            }
                                        }

                                        this.saveDeleted()
                                    }

                                    // освежить заказ
                                    s = localStorage.getItem('order')
                                    if (s !== null) {
                                        this.order = JSON.parse(s)
                                    }

                                    // проверка заказа
                                    for (let key in this.order) {
                                        // есть в заказе, нет в корзине
                                        if (Object.prototype.hasOwnProperty.call(this.cart, key) == false) {
                                            Vue.delete(this.order, key)
                                        }
                                        // неправильное количество
                                        if (Object.prototype.hasOwnProperty.call(this.order, key) != false && this.cart[key].amount < this.order[key].amount) {
                                            this.order[key].amount = this.cart[key].amount
                                        }
                                    }

                                    this.saveOrder()

                                    this.$root.$emit('reload-cart-list', {})
                                    resolve('Корзина синхронизирована.')
                                },
                                err2 => {
                                    reject(err2)
                                }
                            )
                            return
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.$bs5utils.Snack.show('danger', err, this.$store.getters.delay.error)
                        return reject(new Error(err))
                    })
            })
        },
        toggleOrderItem: function (id) {
            return new Promise((resolve) => {
                if (typeof this.order == undefined) {
                    this.order = {}
                }

                let s = null

                // освежить заказ
                s = localStorage.getItem('order')
                if (s !== null) {
                    this.order = JSON.parse(s)
                }

                if (Object.prototype.hasOwnProperty.call(this.order, id) == false) {
                    // нет такого id
                    Vue.set(this.order, id, this.cart[id])

                    resolve('Добавлено в заказ')
                } else {
                    // есть
                    Vue.delete(this.order, id)

                    resolve('Удалено из заказа')
                }

                this.saveOrder()
            })
        },
        removeOrderItem: function (id) {
            return new Promise((resolve) => {
                if (typeof this.order == undefined) {
                    this.order = {}
                }

                let s = null

                // освежить заказ
                s = localStorage.getItem('order')
                if (s !== null) {
                    this.order = JSON.parse(s)
                }

                if (Object.prototype.hasOwnProperty.call(this.order, id)) {
                    // есть
                    Vue.delete(this.order, id)

                    resolve('Удалено из заказа')
                }

                this.saveOrder()
            })
        },
        getOrderTotal: function () {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.$store.getters.urlorder}/total`, {
                        order: this.order,
                    })
                    .then(res => {
                        this.orderTotal = res.data.total;
                        resolve('Сумма заказа получена.')
                        return
                    })
                    .catch(err => {
                        console.error(err)
                        return reject(new Error(err))
                    })
            });
        },
        // служебное
        strJsonPrepare: function (str) {
            const from = ['&amp;', '&lt;', '&gt;', '&quot;', '&#x60;', '&#x27;']
            const to = ['&', '<', '>', '"', '`', '\'']

            from.forEach(function (el, index) {
                str = str.replaceAll(el, to[index])
            })

            return str
        },
        acceptCookie: function (pos) {
            this.$root.$emit('accept-сookie', pos);
        },
    },
    created() {
        // убрать товар из заказа
        this.$root.$on('remove-order-item', (data) => {
            let s = null

            // освежить заказ
            s = localStorage.getItem('order')
            if (s !== null) {
                this.order = JSON.parse(s)
            }

            let id = parseInt(data.id)

            switch (data.type) {
                case 'id':
                    if (Object.prototype.hasOwnProperty.call(this.order, id)) {
                        // есть
                        Vue.delete(this.order, id)
                    }
                    break
                case 'all':
                    Vue.set(this.order, {})
                    break
            }

            this.saveOrder()
        })

        this.$root.$on('order-item-remove', (data) => {
            this.removeOrderItem(data)
        })

        // убрать товар из заказа
        this.$root.$on('toggle-order-item', (id) => {
            this.toggleOrderItem(id)
        })

        //перечитать заказ
        this.$root.$on('reload-order', () => {
            this.cartSync()
        })

        // маркер страницы результатов поиска
        this.$root.$on('page-search', () => {
            this.pageSearch = true
        })

        // запуск синхронизации корзины
        this.$root.$on('cart-sync', () => {
            this.cartSync()
        })

        // добавить в корзину
        this.$root.$on('cart-add', (data) => {
            this.addToCart(data)
        })

        this.$root.$on('cart-item-remove', (data) => {
            this.removeFromCart(data)
        })

        this.$root.$on('cart-item-increase', (data) => {
            this.cartIncreaseAmount(data)
        })

        this.$root.$on('cart-item-reduce', (data) => {
            this.cartReduceAmount(data)
        })

        this.$root.$on('cart-item-change', (data) => {
            this.cartChangeAmount(data)
        })

        // другое
    },
    mounted() {
        // преобразовать старую корзину или просто загрузить
        this.$nextTick(() => {
            this.loadData()
        });

        let elems = null

        // клик в большую превьюшку или "показать ещё"
        elems = ['bigimg_link', 'moreimages_link'];
        if (elems.length) {
            elems.forEach(id => {
                if (document.getElementById(id) !== null) {
                    document.getElementById(id).addEventListener('click', (ev) => {
                        ev.preventDefault();

                        const event = new MouseEvent('click', {
                            view: window,
                            bubbles: true,
                            cancelable: true
                        });

                        document.getElementsByClassName('fancybox-thumb')[0].dispatchEvent(event);
                    });
                }
            })
        }

        // смена большой превьюшки
        elems = document.getElementsByClassName('img_preview')
        if (Object.keys(elems).length) {
            for (const item of document.getElementsByClassName('img_preview')) {
                item.addEventListener('click', (ev) => {
                    ev.preventDefault();
                    for (const el of document.getElementsByClassName('img_preview')) {
                        el.parentNode.classList.remove('selected');
                    }
                    ev.target.parentNode.classList.add('selected');
                    document.getElementById('big_img').setAttribute(`src`, ev.target.getAttribute(`data-href`));
                });
            }
        }

        // укорачивание описания
        elems = document.getElementsByClassName('description')
        if (Object.keys(elems).length) {
            for (const item of elems) {
                let chars = item.dataset.symbols
                let el1 = document.createElement('div')
                let el2 = document.createElement('div')
                el1.classList.add('first')
                el2.classList.add('second', 'd-none')
                let symbols = 0
                for (let child of item.children) {
                    symbols += child.innerText.length
                    if (symbols < chars) {
                        el1.appendChild(child)
                    } else {
                        el2.appendChild(child)
                    }
                }
                let btn = document.createElement('a')
                btn.classList.add('btn', 'btn-link', 'mb-3', 'btn__showmore')
                btn.textContent = 'Читать дальше'
                btn.addEventListener('click', function (ev) {
                    ev.target.previousSibling.classList.remove('d-none')
                    ev.target.remove()
                })
                item.after(el1, el2, btn)
                item.remove()
            }
        }

        // слайдер при наведении на превьюшку
        // new HvrSlider('.images')
    },
}).$mount('#app')
