<template>
  <div class="accordion mb-3" id="accordionOrderForm">
    <p>
      <button
        data-bs-toggle="collapse"
        data-bs-target="#collapseNoLogin"
        aria-expanded="true"
        aria-controls="collapseNoLogin"
        class="btn btn-info me-1"
        v-on:click="setAccordionBtnClass($event)"
        type="button">
        Без входа на сайт
      </button>
      <button
        data-bs-toggle="collapse"
        data-bs-target="#collapseLogin"
        aria-expanded="false"
        aria-controls="collapseLogin"
        class="btn btn-outline-info collapsed me-1"
        v-on:click="setAccordionBtnClass($event)"
        type="button">
        Вход на сайт
      </button>
    </p>

    <!-- заказ без входа -->
    <div
      id="collapseNoLogin"
      aria-labelledby="headingNoLogin"
      class="collapse show"
      data-bs-parent="#accordionOrderForm">
      <div class="card card-body">
        <h3 class="d-none">Заказать без входа на сайт</h3>
        <form
          enctype="multipart/form-data"
          class="needs-validation"
          novalidate
          v-on:submit.prevent="$emit('make-order', form)">
          <div class="row my-1">
            <div class="col">
              <div class="alert alert-info" role="alert">
                Вы можете заполнить поля без входа на сайт, введите свой email и нажмите кнопку поиска
                <font-awesome-icon icon="fas fa-search" />
              </div>
            </div>
          </div>

          <div class="row my-1">
            <label
              for="validation-email"
              class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label"
              ><b class="orange">*</b> Адрес эл. почты</label
            >
            <div class="col-12 col-sm-8 col-xxl-6">
              <div class="input-group">
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  id="validation-email"
                  v-model="form.email"
                  required />
                <span class="input-group-text">
                  <a href="#" title="Найти данные пользователя по email" v-on:click.prevent="searchProfile()"
                    ><font-awesome-icon icon="fas fa-search"
                  /></a>
                </span>
              </div>
              <div class="feedback valid-feedback" :class="{ 'd-block': search.valid }">Заполнено корректно.</div>
              <div class="feedback invalid-feedback" :class="{ 'd-block': search.invalid }">
                Заполните адрес эл. почты.
              </div>
              <div class="feedback success-feedback" :class="{ 'd-block': search.success }">
                Данные профиля найдены.
              </div>
              <div class="feedback fail-feedback" :class="{ 'd-block': search.fail }">
                Данные профиля не найдены. Скорее всего вы у нас ещё ничего не покупали.
              </div>
              <small
                class="text-muted"
                :class="{ 'd-none': search.valid || search.invalid || search.success || search.fail }"
                >Для заказа достаточно указать только адрес эл. почты.</small
              >
            </div>
          </div>

          <hr />

          <div class="row my-1">
            <label for="validation-name" class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label"
              >Имя и фамилия</label
            >
            <div class="col-12 col-sm-8 col-xxl-6">
              <input type="text" name="fullname" class="form-control" id="validation-name" v-model="form.fullname" />
              <div class="valid-feedback">Заполнено корректно.</div>
              <div class="invalid-feedback">Заполните ваше имя.</div>
            </div>
          </div>
          <div class="row my-1">
            <label
              for="validation-phone"
              class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label"
              >Телефон</label
            >
            <div class="col-3 col-sm-3 col-md-2 col-xxl-2 pe-0">
              <select v-model="obj.country.phone" class="form-select">
                <option v-for="el in countries" :key="el.value" :value="el.value">
                  {{ el.text }}
                </option>
              </select>
            </div>
            <div class="col-9 col-sm-5 col-md-4 col-lg-3 col-lg-6 col-xxl-4">
              <input
                v-maska="obj.phone"
                :data-maska="obj.mask.phone"
                v-model="form.phone"
                v-on:maska="onMaskaPhone($event)"
                type="tel"
                class="form-control"
                id="validation-phone"
                :class="{
                  'border border-warning bg-warning bg-opacity-25':
                    obj.phone.unmasked != '' && obj.phone.completed == false,
                  'border border-success bg-success bg-opacity-25': obj.phone.completed == true,
                }" />
            </div>
          </div>
          <div class="row my-1">
            <label
              for="validation-mobile"
              class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label"
              >Доп. телефон</label
            >
            <div class="col-3 col-sm-3 col-md-2 col-xxl-2 pe-0">
              <select v-model="obj.country.mobilephone" class="form-select">
                <option v-for="el in countries" :key="el.value" :value="el.value">
                  {{ el.text }}
                </option>
              </select>
            </div>
            <div class="col-9 col-sm-5 col-md-4 col-lg-3 col-lg-6 col-xxl-4">
              <input
                v-maska="obj.mobilephone"
                :data-maska="obj.mask.mobilephone"
                v-model="form.mobilephone"
                v-on:maska="onMaskaMobilePhone($event)"
                type="tel"
                class="form-control"
                id="validation-mobile"
                :class="{
                  'border border-warning bg-warning bg-opacity-25':
                    obj.mobilephone.unmasked != '' && obj.mobilephone.completed == false,
                  'border border-success bg-success bg-opacity-25': obj.mobilephone.completed == true,
                }" />
            </div>
          </div>

          <hr />

          <div class="row my-1">
            <label class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label">Доставка</label>
            <div class="col-12 col-sm-8">
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  id="nologin-deliverytype1"
                  name="deliverytype"
                  value="1"
                  v-model="form.deliverytype" />
                <label class="form-label form-check-label" for="nologin-deliverytype1">Заберу сам</label>
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  id="nologin-deliverytype2"
                  name="deliverytype"
                  value="2"
                  v-model="form.deliverytype" />
                <label class="form-label form-check-label" for="nologin-deliverytype2"
                  >Доставка по С.-Петербургу
                  <small class="text-muted"><a href="/dostavka">стоимость и какие районы?</a></small></label
                >
              </div>
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  id="nologin-deliverytype3"
                  name="deliverytype"
                  value="3"
                  v-model="form.deliverytype" />
                <label class="form-label form-check-label" for="nologin-deliverytype3"
                  >Отправка ТК <small class="text-muted"><a href="/dostavka#tk">какие ТК используем?</a></small></label
                >
              </div>
            </div>
          </div>
          <div class="row my-1" v-if="form.deliverytype == 3">
            <label for="validation-tk" class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label"
              >Транспортная компания</label
            >
            <div class="col-12 col-sm-8 col-xxl-6">
              <select name="tk" class="form-control" id="validation-tk" v-model="form.tk">
                <option v-for="el in values.tklist" :key="el.id" :value="el.id">{{ el.name }} {{ el.comment }}</option>
              </select>
              <small class="text-muted"
                >Мы отправляем все заказы в жёсткой обрешетке, потому что это требование транспортных компаний к
                отправке автодеталей.</small
              >
            </div>
          </div>
          <div class="row" v-if="form.deliverytype == 2 || form.deliverytype == 3">
            <label class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label"
              >Адрес доставки</label
            >
            <div class="col-12 col-sm-8 col-xxl-6 position-relative">
              <VueSuggestions
                :model.sync="form.address"
                :coordinates.sync="form.coordinates"
                :placeholder="'Начните вводить'"
                class="form-control"
                :options="suggestionOptions">
              </VueSuggestions>
            </div>
          </div>

          <hr />

          <div class="row my-1">
            <label
              for="validation-comment"
              class="col-12 col-sm-4 col-md-3 col-lg-3 col-form-label text-sm-end form-label"
              >Комментарий</label
            >
            <div class="col-12 col-sm-8 col-xxl-6">
              <textarea class="form-control" name="comment" id="validation-comment" v-model="form.comment"></textarea>
            </div>
          </div>

          <div class="row my-1">
            <div class="col-12 col-sm-8 offset-sm-4 col-xxl-6 offset-xxl-3 text-sm-end">
              <button class="btn btn-primary" type="submit">Отправить заказ</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- войти и потом уже -->
    <div id="collapseLogin" aria-labelledby="headingLogin" class="collapse" data-bs-parent="#accordionOrderForm">
      <div class="card card-body">
        <h3 class="d-none">Войти на сайт</h3>
        <form
          class="needs-validation"
          :class="{ 'was-validated': formlogin.validated }"
          novalidate
          v-on:submit.prevent="doLogin($event)">
          <input type="hidden" name="formid" v-model="form.formid" />
          <input type="hidden" name="_token" v-model="form._token" />

          <div class="row">
            <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
              <div class="my-1">
                <div class="input-group">
                  <div class="input-group-text"><font-awesome-icon icon="fas fa-at" /></div>
                  <input
                    type="text"
                    placeholder="Эл. почта"
                    name="username"
                    aria-describedby="formLoginOrderUsernameFeedback"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        Object.hasOwn(formlogin.errors, 'username') && Object.keys(formlogin.errors.username).length,
                    }"
                    v-model.trim="login.username"
                    required />
                </div>
                <div
                  v-for="(value, key) in formlogin.errors.username"
                  :key="key"
                  class="invalid-feedback d-block"
                  id="formLoginOrderUsernameFeedback">
                  {{ value }}
                </div>
              </div>
              <div class="my-1">
                <div class="input-group">
                  <div class="input-group-text"><font-awesome-icon icon="fas fa-key" /></div>
                  <input
                    type="password"
                    placeholder="Пароль"
                    name="password"
                    aria-describedby="formLoginOrderPasswordFeedback"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        Object.hasOwn(formlogin.errors, 'password') && Object.keys(formlogin.errors.password).length,
                    }"
                    v-model="login.password"
                    required />
                </div>
                <div
                  v-for="(value, key) in formlogin.errors.password"
                  :key="key"
                  class="invalid-feedback d-block"
                  id="formLoginOrderPasswordFeedback">
                  {{ value }}
                </div>
              </div>

              <div v-for="(value, key) in formlogin.messages" :key="key" class="my-1 p-2 alert alert-info">
                {{ value }}
              </div>
            </div>
            <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 my-2 text-end">
              <button type="submit" class="btn btn-primary" :disabled="isLoading">
                <font-awesome-icon icon="fas fa-user"></font-awesome-icon> Войти
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faUser, faKey, faAt, faSearch } from '@fortawesome/free-solid-svg-icons';
library.add(faUser, faKey, faAt, faSearch);

import VueSuggestions from 'vue-suggestions';
import { vMaska } from 'maska';

export default {
  directives: { maska: vMaska },
  data: function () {
    return {
      form: {
        formid: 'order-forms-nologin',
        _token: this.csrf,
        email: '',
        fullname: '',
        phone: '',
        mobilephone: '',
        deliverytype: 1,
        tk: 'na',
        comment: '',
        address: '',
        coordinates: {
          latitude: '',
          longitude: '',
        },
      },
      // для работы maska
      obj: {
        phone: {
          completed: false,
          masked: '',
          unmasked: '',
        },
        mobilephone: {
          completed: false,
          masked: '',
          unmasked: '',
        },
        raw: {
          phone: '',
          mobilephone: '',
        },
        country: {
          phone: '?',
          mobilephone: '?',
        },
        mask: {
          phone: '',
          mobilephone: '',
        },
      },
      // справочники для maska
      countries: [
        // https://apps.timwhitlock.info/emoji/tables/iso3166
        { text: '🇷🇺', code: 'RU', value: '7', mask: '+7 (###) ###-##-##', compare: '+7 ' }, // 7 XXX XXX-XX-XX // россия
        { text: '🇧🇾', code: 'BY', value: '375', mask: '+375 ## ###-##-##', compare: '+375 ' }, // 375 XX XXX-XX-XX // белоруссия
        { text: '🇦🇲', code: 'AM', value: '374', mask: '+374 ### ###-##', compare: '+374 ' }, // 374 XXX XXX-XX // армения
        // { text: '🇱🇻', code: 'LV', value: '371', mask: '+371 ### ## ###', compare: '+371 ' }, // 371 XXX XX XXX // латвия
        // { text: '🇱🇹', code: 'LT', value: '370', mask: '+370 ### #####', compare: '+370 ' }, // 370 XXX XXXXX // литва
        { text: 'другой', code: '??', value: '?', mask: '', compare: '' }, // другое, что попало
      ],
      default: {
        country: '7',
        mask: '+7 (###) ###-##-##',
      },
      // datata token
      token: process.env.VUE_APP_DADATA_API_KEY,
      // dadata
      suggestionOptions: {
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
        token: process.env.VUE_APP_DADATA_API_KEY,
        type: 'ADDRESS',
        scrollOnFocus: false,
        triggerSelectOnBlur: false,
        triggerSelectOnEnter: false,
        addon: 'none',
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
        //onSelect(suggestion) { /* */ },
      },
      // данные логина
      login: {
        formid: 'order-do-login',
        _token: this.csrf,
        username: '',
        password: '',
      },
      // сообщения формы логина
      formlogin: {
        errors: {},
        messages: [],
        validated: false,
      },
      // поиск по логину
      search: {
        valid: false,
        invalid: false,
        success: false,
        fail: false,
      },
      isLoading: false,
    };
  },
  props: {
    csrf: {
      type: String,
      default: '',
    },
    values: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    VueSuggestions,
    FontAwesomeIcon,
  },
  methods: {
    setAccordionBtnClass: function (ev) {
      let elems = document.querySelectorAll('.btn[data-bs-toggle=collapse]');
      elems.forEach(function (el) {
        el.classList.replace('btn-info', 'btn-outline-info');
      });
      if (ev.target.attributes['aria-expanded'].value) {
        ev.target.classList.replace('btn-outline-info', 'btn-info');
      }
    },
    searchProfile: function () {
      this.search.valid = false;
      this.search.invalid = false;
      this.search.success = false;
      this.search.fail = false;

      if (this.form.email == '') {
        this.cleanFields();

        this.$bs5utils.Snack.show(
          'warning',
          'Нужно заполнить поле "Адрес эл.почты" для поиска',
          this.$store.getters.delay.warning
        );
        this.search.invalid = true;
        return false;
      }

      if (this.form.email.length < 4) {
        this.cleanFields();

        this.$bs5utils.Snack.show(
          'warning',
          'Недостаточно символов для поиска в поле',
          this.$store.getters.delay.warning
        );
        this.search.invalid = true;
        return false;
      }

      axios
        .get(`${this.$store.getters.urlorder}/finduser?email=${this.form.email}`)
        .then((res) => {
          if (res.data.status == true) {
            // удалось
            Object.keys(res.data.values).forEach((key) => {
              this.form[key] = res.data.values[key];
            });

            this.formlogin.validated = false;
            Object.assign(this.formlogin.errors, {});
            this.formlogin.messages = [];

            this.search.success = true;
            this.search.fail = false;
            this.$bs5utils.Snack.show('success', res.data.message, this.$store.getters.delay.success);
          } else {
            // не удалось
            this.cleanFields();

            this.search.success = false;
            this.search.fail = true;
            this.$bs5utils.Snack.show('warning', res.data.message, this.$store.getters.delay.warning);
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bs5utils.Snack.show('danger', err, this.$store.getters.delay.error);
        });
    },
    onMaskaPhone(event) {
      this.obj.raw.phone = event.detail.unmasked;
    },
    onMaskaMobilePhone(event) {
      this.obj.raw.mobilephone = event.detail.unmasked;
    },
    doLogin: function () {
      this.isLoading = true;

      this.formlogin.errors = {};
      this.formlogin.messages = [];
      this.formlogin.validated = false;

      axios
        .post(`/evocms-user/auth`, this.login)
        .then((res) => {
          this.formlogin.validated = true;

          if (res.data.status == 'ok') {
            this.formlogin.messages = ['Вход на сайт удался!'];

            this.$emit('get-form-values');
            this.$bs5utils.Snack.show('success', 'Успешный вход!', this.$store.getters.delay.success);
          }

          if (res.data.status == 'error') {
            this.$bs5utils.Snack.show(
              'warning',
              'Вход не удался, проверьте введённые данные.',
              this.$store.getters.delay.warning
            );

            const errors = res.data.errors || {};
            const fieldErrors = errors.validation || {};
            const customErrors = errors.customErrors || {};
            const commonErrors = errors.common || [];

            Object.keys(fieldErrors).forEach((key) => {
              this.formlogin.errors[key] = fieldErrors[key];
            });

            Object.keys(customErrors).forEach((key) => {
              this.formlogin.errors[key] = customErrors[key];
            });

            this.formlogin.messages = commonErrors;
          }
        })
        .catch((err) => {
          Object.assign(this.formlogin.errors, {});
          this.formlogin.messages = ['Ошибка на сервере, обновите страницу и попробуйте ещё раз.'];
          this.formlogin.validated = false;
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cleanFields: function () {
      ['fullname', 'phone', 'mobilephone', 'address'].forEach((key) => {
        this.form[key] = '';
      });
    },
  },
  watch: {
    'obj.country.phone'(newVal) {
      this.obj.mask.phone = this.countries.filter((el) => el.value == newVal)[0].mask;
      this.form.phone = this.obj.raw.phone;
    },
    'obj.country.mobilephone'(newVal) {
      this.obj.mask.mobilephone = this.countries.filter((el) => el.value == newVal)[0].mask;
      this.form.mobilephone = this.obj.raw.mobilephone;
    },
  },
  mounted() {
    this.obj.country.phone = this.default.country;
    this.obj.mask.phone = this.default.mask;

    this.obj.country.mobilephone = this.default.country;
    this.obj.mask.mobilephone = this.default.mask;
  },
};
</script>
