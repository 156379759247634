<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faUser } from '@fortawesome/free-solid-svg-icons';
library.add(faUser);

export default {
  data: function () {
    return {
      //
    };
  },
  props: {
    //
  },
  components: {
    // eslint-disable-next-line
    FontAwesomeIcon,
  },
  mounted() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    // eslint-disable-next-line
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
};
</script>
