<template>
  <!-- заказ -->
  <div v-if="loading">
    <!-- placeholder -->
    <div v-for="n in items.length == 0 ? 1 : items.length" :key="n" class="mt-1 mb-4" aria-hidden="true">
      <div class="row py-2 row_detail placeholder-glow">
        <div data-descr="описание" class="col col-8 order-5 col-sm-8 order-sm-3 col-md-5 col-xl-6 mb-2">
          <span class="placeholder col-12 bg-info"></span>
          <!-- туть -->
          <span class="placeholder col-7"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-6"></span>
          <span class="placeholder col-8"></span>
        </div>
        <div data-descr="фотки" class="col col-4 order-4 col-sm-3 offset-sm-0 order-sm-2 col-md-3 col-lg-3 col-xl-2">
          <span class="placeholder col-12"><br /><br /><br /><br /></span>
        </div>
        <div data-descr="цена" class="col col-8 order-2 col-sm-10 order-sm-4 col-md-3 col-lg-3 text-right prices">
          <span class="placeholder col-12"></span>
        </div>
        <div data-descr="кнопка" class="col col-2 order-3 col-sm-2 order-sm-5 col-md-1">
          <!-- туть -->
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="warning.length" class="alert alert-warning" role="alert" v-html="warning.join('<br />')"></div>
    <div class="mt-1 mb-4" v-if="items.length">
      <template v-for="item in items">
        <order-list-detail v-if="item.template == 'detail'" :key="item.content_id" :item="item"></order-list-detail>
        <order-list-other v-else-if="item.template == 'other'" :key="item.content_id" :item="item"></order-list-other>
        <template v-else>Нет товара в корзине.</template>
      </template>
    </div>
    <div v-else>
      <p>
        Для оформления заказа перейдите <a href="/cart">на страницу корзины</a> и выберите товары, которые хотите
        заказать.
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import OrderListDetail from './order-list-detail.vue';
import OrderListOther from './order-list-other.vue';

export default {
  name: 'OrderList',
  components: {
    OrderListDetail,
    OrderListOther,
  },
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: function () {
    return {
      loading: true,
      //
      // тут полученные данные по заказу
      items: [],
      warning: [],
    };
  },
  watch: {
    order: {
      deep: true,
      // eslint-disable-next-line
      handler(vals, oldVal) {
        this.getOrder(this.order).finally(() => {
          this.loading = false;
        });
      },
    },
  },
  methods: {
    getOrder: function (vals) {
      return new Promise((resolve, reject) => {
        if (vals === undefined) {
          resolve('Нет данных о заказе.');
          return;
        }

        if (Object.keys(vals).length == 0) {
          this.items = [];
          resolve('В заказе ничего нет.');
          return;
        }

        axios
          .post(`${this.$store.getters.urlorder}/get`, { order: vals })
          .then((res) => {
            if (res.data.status) {
              this.items = res.data.result;
              this.warning = res.data.warning;

              //bs5Utils.Snack.show('info', 'Список заказа загружен.', this.$store.getters.delay.info)
              resolve('Список заказа загружен.');
              return;
            } else {
              this.items = [];
              this.warning = [];

              this.$bs5utils.Snack.show('info', 'В заказе ничего нет.', this.$store.getters.delay.info);
              resolve('Ошибка показа заказе, в заказе ничего нет.');
              return;
            }
          })
          .catch((err) => {
            this.items = [];
            this.warning = [];

            console.error(err);

            this.$bs5utils.Snack.show('danger', err, this.$store.getters.delay.error);
            reject(new Error(err));
            return;
          });
      });
    },
  },
};
</script>
