<template>
  <ul class="navbar-nav">
    <slot></slot>
  </ul>
</template>

<script>
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faHouse } from '@fortawesome/free-solid-svg-icons';
library.add(faHouse);

export default {
  data: function () {
    return {
      //
    };
  },
  props: {
    //
  },
  components: {
    // eslint-disable-next-line
    FontAwesomeIcon,
  },
};
</script>
