<template>
  <div v-if="loading" aria-hidden="true" class="placeholder-glow row">
    <!-- placeholder -->
    <a href="#" tabindex="-1" class="btn btn-secondary ms-2 mb-2 disabled placeholder col-4" aria-hidden="true"></a>
  </div>
  <div v-else class="mb-1">
    <a
      v-if="cart.hasOwnProperty(contentid)"
      href="/cart"
      class="btn btn-primary btn-sm px-4 button_incart"
      title="Перейти в корзину"
      >В корзине</a
    >
    <button
      v-else
      class="btn btn-primary btn-sm px-4 button_add"
      type="button"
      v-on:click="$emit('add', { contentid: contentid, extra: extra })"
      title="Добавить в корзину">
      Купить
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonAddToCart',
  data: function () {
    return {
      loading: true,
    };
  },
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    contentid: {
      type: Number,
      default: 0,
    },
    extra: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    cart: {
      deep: true,
      // eslint-disable-next-line
      handler(val, oldVal) {
        this.loading = false;
      },
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>
